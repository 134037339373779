module.exports = [{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"withWebp":true,"ignoreFileExtensions":[]},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/src/cms/index.js"},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true}},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src386250504/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/jon.wilsons.online/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
